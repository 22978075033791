import {inject} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {DialogService} from "aurelia-dialog";
import {DocumentsPreviewDialog} from "../../documents-preview/documents-preview-dialog";
import {DialogView} from "../../view/dialog-view";
import {EventAggregator} from "aurelia-event-aggregator";

@inject(
    DialogService, EventAggregator
)
export class DocumentsPreviewActionHandler extends ActionHandlerInterface
{
    constructor(
        dialog,
        ea
    ) {
        super();

        this.dialog = dialog;
        this.ea = ea;
    }

    getActionType()
    {
        return 'documents-preview';
    }

    getReturnContext(config, context)
    {
        return Object.assign(
            {
                previewGeneratorId: config.previewGeneratorId,
                previewPreset: config.previewPreset,
                previewPrevious: config.previewPrevious,
                updateModelIds: config.updateModelIds,
                title: config.title,
                formId: config.formId,
                additionalData: config.additionalData
            },
            context
        );
    }

    getAction(returnContext)
    {
        return () => {

            let previewContext = {
                id: returnContext.id,
                modelId: returnContext.modelId,
                previewGeneratorId: returnContext.previewGeneratorId,
                previewPreset: returnContext.previewPreset,
                previewPrevious: returnContext.previewPrevious,
                title: returnContext.title,
                additionalData: returnContext.additionalData
            };

            if (returnContext.formId) {

                return this.dialog.open(
                    {
                        viewModel: DialogView,
                        model: Object.assign(
                            {},
                            returnContext.contextObj || returnContext.data || {}, // allow templating in title
                            {
                                formContext: previewContext,
                                title: returnContext.confirmTitle, // @fixme rename to title
                                submitHandler: this.workflowFormSubmit.bind(this),
                                finishContextHandler: this.finishContext.bind(this),
                                content: '<sio-form config.bind="\'' + returnContext.formId + '\'" ' +
                                    'context-object-ref.bind="params.formContext" ' +
                                    'disable-local-storage.bind="true" ' +
                                    'params.bind="{bindValues: params.formContext.bindValues}" ' +
                                    'submit.bind="params.submitHandler" ' +
                                    'sio-post-submit.trigger="params.finishContextHandler(dialogController)"></sio-form>',
                            })
                    }
                ).whenClosed(data => {
                    return data.output ? data.output : data;
                });
            }

            return this.dialog.open(
                {
                    viewModel: DocumentsPreviewDialog,
                    model: previewContext
                }
            ).whenClosed(data => {

                for (const modelId of returnContext.updateModelIds ?? []) {
                    this.ea.publish('sio_form_post_submit', {config: {modelId}});
                }

                return data;
            });
        };
    }

    finishContext(dialogController) {
        if (dialogController && this.currentData) {
            dialogController.ok(this.currentData);
        }
        this.currentData = null;
    }

    workflowFormSubmit(value) {

        let previewContext = Object.assign(value.context, {additionalData: value.object});

        return this.dialog.open(
            {
                viewModel: DocumentsPreviewDialog,
                model: previewContext
            }
        );
    }
}
