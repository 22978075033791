import {inject, LogManager} from "aurelia-framework";
import {ActionHandlerInterface} from "./action-handler.interface";
import {DialogView} from "../../view/dialog-view";
import {DialogService} from "aurelia-dialog";
import {Router} from "aurelia-router";

const logger = LogManager.getLogger('action-handler');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@inject(
    DialogService,
    Router
)
export class ViewActionHandler extends ActionHandlerInterface {
    constructor(
        dialog,
        router
    ) {
        super();

        this.dialog = dialog;
        this.router = router;
    }

    getActionType() {
        return 'view';
    }

    getReturnContext(config, context) {
        return Object.assign(
            {
                moduleId: config.viewId.split('/')[0],
                viewId: config.viewId.split('/')[1],
                bindValues: config.bindValues,
                modal: config.modal,
                title: config.title,
                bulk: config.bulk || false
            },
            context,
            config.viewContext ?? {}
        );
    }

    getAction(returnContext) {
        return () => {
            // noinspection JSCheckFunctionSignatures
            logger.debug('Performing `view` action with context ', returnContext);

            if (returnContext.modal) {
                return this.dialog
                    .open({viewModel: DialogView, model: returnContext})
                    .whenClosed(
                        ({wasCancelled, output}) => wasCancelled ? Promise.reject() : Promise.resolve(output)
                    );
            } else {
                this.router.navigateToRoute('view', returnContext);
            }
        };
    }
}
