import {bindable, customElement, inject} from 'aurelia-framework';
import * as _ from "lodash";
import {Client} from '../api/client';

@customElement('action-display')
@inject(Client)
export class ActionDisplay
{
    @bindable object;

    rowActions = [];

    constructor(client)
    {
        this.client = client;
    }

    objectChanged()
    {
        this.rowActions = [];

        _.each(this.object.actions, (action) => {
            if (this.isAllowedAction(action)) {
                this.rowActions.push(action);
            }
        });
    }

    isAllowedAction(action)
    {
        return action.preset !== 'edit' && action.preset !== 'display' && action.preset !== 'delete' && true !== action.bulkOnly;
    }

    context(action, targetEntity)
    {
        const context = _.cloneDeep(targetEntity);

        context.contextObjectRef = {
        };

        return {
            id: targetEntity.id,
            modelId: targetEntity.modelId,
            contextObj: targetEntity
        };
    }
}
