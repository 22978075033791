import {
    AnnotationViewer,
    AnnotationSidebar,
    formatPrediction
} from "react-mindee-js";
import React, {useEffect, useState} from "react";
import * as pdfjs from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/".concat(pdfjs.version, "/pdf.worker.js");

//Todo replace with library solution, when they do fix pdf image error
var getImageFromPage = function getImageFromPage(pdf, pageNumber) {
    return new Promise(function (resolve, reject) {
        pdf.getPage(pageNumber).then(function (page) {
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            var viewport = page.getViewport({
                scale: 2.0
            });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            var renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            page.render(renderContext).promise.then(function () {
                resolve(canvas.toDataURL());
            })["catch"](function () {
                reject(null);
            });
        })["catch"](function () {
            reject(null);
        });
    });
};

var getImagesFromPDF = function getImagesFromPDF(file) {
    return new Promise(function (resolve, reject) {
        pdfjs.getDocument(file).promise.then(function (pdf) {
            Promise.all(Array.from(Array(pdf.numPages).keys()).map(function (index) {
                return getImageFromPage(pdf, index + 1);
            })).then(function (images) {
                resolve(images);
            })["catch"](function () {
                reject([]);
            });
        });
    });
};

export const AnnotationViewerReact = (props) => {
    const [pdfPages, setPdfPages] = useState([]);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (props.file.includes('pdf')) {
            getImagesFromPDF(props.file).then((_pdfPages) => {
                setPdfPages(_pdfPages);
            });
        } else {
            setPdfPages([props.file]);
        }
    }, [props.file]);

    return (
        <div>
            {
                pdfPages.length > 0 &&
                <div style={{height: '800px', width: '100%'}}>
                    <AnnotationViewer
                        image={pdfPages[index]}
                        shapes={formatPrediction(props.predictions?.[index] || [])}
                    />
                    {pdfPages.length > 1 ?
                        <AnnotationSidebar
                            items={pdfPages}
                            activeIndex={index}
                            onChange={setIndex}
                        /> : null}
                </div>
            }
        </div>
    );
};
