import * as _ from "lodash";

export class ActionConfigPreparation
{
    static presets = {
        new: {
            type: 'view',
            icon: 'plus',
            actionClass: 'btn btn-success btn-sm',
            label: 'label.new',
            showLabel: false,
            modal: true,
            name: 'create'
        },
        edit: {
            type: 'view',
            icon: 'pencil',
            actionClass: 'btn btn-primary',
            label: 'label.edit',
            showLabel: false,
            name: 'edit'
        },
        display: {
            type: 'view',
            //Todo better search icon
            icon: 'glyphicon-share-alt',
            actionClass: 'btn btn-primary',
            label: 'label.display',
            showLabel: false,
            name: 'display'
        },
        pencil: {
            type: 'view',
            icon: 'pencil',
            actionClass: 'btn btn-link pull-right',
            label: 'label.edit',
            showLabel: false,
            name: 'pencil',
            modal: true
        },
        delete: {
            type: 'delete',
            icon: 'trash',
            actionClass: 'btn btn-danger',
            label: 'label.delete',
            showLabel: false,
            name: 'delete'
        },
        archive: {
            type: 'archive',
            icon: 'archive',
            actionClass: 'btn btn-warning',
            label: 'action.archive',
            showLabel: true,
            name: 'archive'
        },
        unarchive: {
            type: 'archive',
            icon: 'unarchive',
            actionClass: 'btn btn-warning',
            label: 'action.unarchive',
            showLabel: true,
            name: 'unarchive'
        }
    };

    /*public*/ static prepare(config)
    {
        config = _.cloneDeep(config);

        if (config.preset && ActionConfigPreparation.presets[config.preset] != null) {
            config = Object.assign({}, ActionConfigPreparation.presets[config.preset], config);
        }

        if (config.actionClass !== undefined) {
            config.buttonClass = config.actionClass;
        }

        return config;
    }
}
