import {bindable, computedFrom, containerless, customElement, inject} from 'aurelia-framework';
import {FlashService} from '../flash/flash-service';
import {StandardActions} from './standard-actions';
import {ActionConfigPreparation} from "./action-config-preparation";
import {EventAggregator} from 'aurelia-event-aggregator';
import {Router} from 'aurelia-router';
import * as _ from 'lodash';
import printJS from 'print-js';
import {I18N} from 'aurelia-i18n';


import "./action.less";

@customElement('sio-action')
@containerless()
@inject(FlashService, StandardActions, EventAggregator, Router, I18N)
export class ActionClass {
    @bindable config;
    @bindable action;
    @bindable context = {};

    @bindable onAfterSuccess = undefined; // Hook: Called after successful action execution

    element;
    loading = false;

    disabledByActiveForm = false;

    constructor(flash, standard, ea, router, i18n) {
        this.flash = flash;
        this.standard = standard;
        this.ea = ea;
        this.router = router;
        this.i18n = i18n
    }

    @computedFrom('_config.buttonClass', 'loading', '_config.disabled')
    get buttonClass() {
        let buttonClass;

        if (this._config.buttonClass !== undefined) {
            buttonClass = this._config.buttonClass;
        } else {
            buttonClass = 'btn btn-default btn-sm';
        }

        if (!this._config.icon) {
            buttonClass += ' no-icon';
        } else {
            if (!/^(glyphicon|sio-icon-)/.test(this._config.icon)) {
                this._config.icon = 'sio-icon-' + this._config.icon;
            }
            this._config.icon += ' glyphicon';
        }

        if (this._config.disabled || this.loading) {
            buttonClass += ' disabled';
        }

        return buttonClass + ' btn-sm sio-action';
    }

    attached() {
        this.disableActionsSubscription = this.ea.subscribe('sio_disable_actions', () => {

            if ('workflow' === this._config.type) {
                this.disabledByActiveForm = true;
                this._config.disabled = true;
            }
        });

        this.enableActionsSubscription = this.ea.subscribe('sio_enable_actions', () => {

            this.disabledByActiveForm = false;
            this._config.disabled = false;
        });

        this.formPreSubmitSubscription = this.ea.subscribe('sio_form_post_submit', () => {

            this.disabledByActiveForm = false;
            this._config.disabled = false;
        });

        this.formCancelSubscription = this.ea.subscribe('sio_form_cancel', () => {

            this.disabledByActiveForm = false;
            this._config.disabled = false;
        });

        this.formValueChangedSubscription = this.ea.subscribe('sio_form_value_changed', () => {

            if ('workflow' === this._config.type) {
                this.disabledByActiveForm = true;
                this._config.disabled = true;
            }
        });
    }

    detached() {
        if (this.disableActionsSubscription) {
            this.disableActionsSubscription.dispose();
        }

        if (this.enableActionsSubscription) {
            this.enableActionsSubscription.dispose();
        }

        if (this.formPreSubmitSubscription) {
            this.formPreSubmitSubscription.dispose();
        }

        if (this.formCancelSubscription) {
            this.formCancelSubscription.dispose();
        }

        if (this.formValueChangedSubscription) {
            this.formValueChangedSubscription.dispose();
        }
    }

    // noinspection JSUnusedGlobalSymbols
    configChanged() {
        this._config = ActionConfigPreparation.prepare(this.config);

        if (this.disabledByActiveForm) {
            this._config.disabled = true;
        }

        this.tryRecomputeAction();
    }

    // noinspection JSUnusedGlobalSymbols
    contextChanged() {
        this.tryRecomputeAction();
    }

    // noinspection JSUnusedGlobalSymbols
    actionChanged() {
        this.tryRecomputeAction();
    }

    tryRecomputeAction() {
        // Reset context, because if it was set previously as standardAction.context,
        // changes in action could lead to inconsistency
        this._context = this.context ? _.cloneDeep(this.context) : {};

        if (!this.action && this._config && this._config.type) {
            let standardAction = this.standard.getAction(this._config, this._context);

            if (standardAction) {
                this._action = standardAction.action;
                this._context = standardAction.context;
            }
        } else if (this._config.actionCallback) {
            this._action = this._config.actionCallback;
        } else {
            this._action = this.action ? this.action : undefined;
        }
    }

    // Title Text
    titleText(config){
        return this.i18n.tr(config.popupHint ?? config.title ?? config.label )
    }

    click() {
        if (this._config.disabled || this.loading) {
            return false;
        }

        let result = this._action();

        if (!result || true === result) {
            return false;
        }

        this.loading = true;

        result.then(data => {
            if (this._config.hasOwnProperty('openUrlProperty')) {

                const url = _.get(data, this._config.openUrlProperty);

                if (url) {
                    const win = window.open(url, '_blank');
                    win.focus();
                }

            } else if (this._config.hasOwnProperty('downloadProperty')) {

                const url = _.get(data, this._config.downloadProperty);

                if (url) {
                    window.location.href = url;
                }

            } else if (this._config.hasOwnProperty('printProperty')) {

                const url = _.get(data, this._config.printProperty);

                //Printing only supports api domain atm, as it needs CORS headers appropiately set
                if (url) {
                    printJS({printable: url, type: 'pdf'});
                }

            } else if (this._config.hasOwnProperty('redirect')) {

                const url = _.get(data, this._config.redirect);

                if (url) {
                    this.router.navigate(url);
                }

            } else if (this._config.reload) {

                location.reload();

            }

            if (this.onAfterSuccess) {
                this.onAfterSuccess();
            }

        }, error => {
            if (error?.status || error?.data) {
                console.error('Action error', error);

                if (403 === error.status) {
                    this.flash.error('sio.access_denied');
                } else {
                    const response = error.data;

                    if (response.message) {
                        this.flash.error(response.message);
                    } else {
                        this.flash.error('action.could_not_be_executed');
                    }
                }
            }
        }).finally(() => {
            this.loading = false;
        });
    }
}
